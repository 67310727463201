@import "../../sass/query";
.cursor {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	transition: all 100ms ease-out;
	z-index: 1;
	mix-blend-mode: exclusion;
	-webkit-mix-blend-mode: exclusion;
	-moz-mix-blend-mode: exclusion;
	transition: all 300ms ease-out;
	@include mobile {
		display: none;
	}
}
