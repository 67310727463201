@import url(https://fonts.googleapis.com/css?family=Karla:400,700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #009950;
  color: white;
  font-size: 18px;
  line-height: 27px; }
  body ::-moz-selection {
    background: black; }
  body ::selection {
    background: black; }
  body ::-moz-selection {
    background: black; }
  @media (max-width: 768px) {
    body {
      font-size: 14px;
      line-height: 18px; } }

main {
  width: calc(100vw - 100px);
  padding-right: 50px; }
  @media (max-width: 768px) {
    main {
      width: 100%;
      padding: 20px;
      text-align: center; } }

.App {
  display: flex; }

h1 {
  font-size: 120px;
  letter-spacing: -10px;
  font-weight: 700;
  line-height: 100px; }
  @media (max-width: 768px) {
    h1 {
      font-size: 48px;
      line-height: 50px;
      letter-spacing: -3px; } }

h2 {
  font-size: 96px;
  letter-spacing: -5px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 96px; }
  @media (max-width: 768px) {
    h2 {
      font-size: 36px;
      line-height: 40px;
      letter-spacing: -3px; } }

h3 {
  font-size: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  margin-top: 50px;
  line-height: 36px; }
  h3:after {
    content: "";
    width: calc(100% - 300px);
    display: block;
    height: 1px;
    background: #00e88d;
    position: absolute;
    bottom: 50%;
    right: 0; }
    @media (max-width: 768px) {
      h3:after {
        display: none; } }

h4 {
  font-weight: bold;
  position: relative;
  margin-bottom: 20px; }
  h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background: white; }

.button {
  margin-top: 40px;
  display: inline-block;
  padding: 20px 40px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  background: black;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  outline: none; }
  .button:hover {
    background: #ffdb00;
    color: black; }

.filter {
  display: none; }

.side-bar {
  padding: 20px 0;
  height: 100vh;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2; }
  @media (max-width: 768px) {
    .side-bar {
      display: none; } }
  .side-bar .logo:hover path {
    fill: black; }
  .side-bar .social-links {
    display: flex;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    position: absolute;
    bottom: 35%; }
  .side-bar .social-links li {
    float: left;
    padding: 0 50px;
    font-size: 12px; }
    .side-bar .social-links li a {
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      position: relative;
      -webkit-transition: color 200ms ease-out;
      transition: color 200ms ease-out;
      font-weight: 700; }
    .side-bar .social-links li a:before {
      content: "";
      display: block;
      background: #ffdb00;
      height: 12px;
      width: 100%;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      position: absolute;
      top: 0;
      left: 0; }
    .side-bar .social-links li a:hover {
      color: black;
      font-weight: 700;
      cursor: none; }
    .side-bar .social-links li a:hover::before {
      -webkit-animation: linkHover 500ms ease-out forwards;
              animation: linkHover 500ms ease-out forwards; }

@-webkit-keyframes linkHover {
  0% {
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  50% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: left center;
            transform-origin: left center; }
  60% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: right center;
            transform-origin: right center; }
  100% {
    -webkit-transform-origin: right center;
            transform-origin: right center;
    -webkit-transform: scaleX(0);
            transform: scaleX(0); } }

@keyframes linkHover {
  0% {
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  50% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: left center;
            transform-origin: left center; }
  60% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: right center;
            transform-origin: right center; }
  100% {
    -webkit-transform-origin: right center;
            transform-origin: right center;
    -webkit-transform: scaleX(0);
            transform: scaleX(0); } }

.header {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 60% 1fr;
  align-items: center; }
  .header .logo-mobile {
    display: none; }
  @media (max-width: 768px) {
    .header {
      grid-template-columns: 100% 0;
      height: auto;
      padding: 50px 0; }
      .header .sketch {
        display: none; }
      .header .logo-mobile {
        display: block; } }
  .header .name {
    font-size: 30px;
    padding-bottom: 20px; }
    @media (max-width: 768px) {
      .header .name {
        font-size: 16px;
        margin-top: 20px; } }
  .header .links {
    display: none;
    margin-top: 20px; }
    .header .links a {
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 700;
      padding: 10px; }
    @media (max-width: 768px) {
      .header .links {
        display: block; } }

.works {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column; }
  @media (max-width: 768px) {
    .works {
      height: auto; } }

.work-content {
  margin: auto;
  display: grid;
  grid-template-columns: 460px 1fr; }
  @media (max-width: 768px) {
    .work-content {
      width: 100%;
      display: flex;
      flex-direction: column; } }
  .work-content h2 {
    margin-top: 20px;
    position: relative;
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px); }
    @media (max-width: 768px) {
      .work-content h2 {
        -webkit-transform: translateX(0);
                transform: translateX(0); } }

.work {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 420px 1fr; }
  @media (max-width: 768px) {
    .work {
      width: 100%;
      display: flex;
      flex-direction: column;
      order: 2;
      margin-top: 40px; } }
  .work .role {
    color: black;
    margin-bottom: 20px; }
  .work .work-image {
    height: 400px; }
    @media (max-width: 768px) {
      .work .work-image {
        width: 100%;
        height: 200px; } }
    .work .work-image img,
    .work .work-image video {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .work article {
    padding-left: 40px;
    height: 400px;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      .work article {
        padding: 0; } }
  .work .description {
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2; }
    @media (max-width: 768px) {
      .work .description {
        -webkit-columns: 1;
           -moz-columns: 1;
                columns: 1; } }
  .work .links {
    padding: 20px 0; }
    .work .links a {
      padding: 5px 10px;
      font-size: 12px;
      text-decoration: none;
      color: black;
      text-transform: uppercase;
      font-weight: 700;
      margin-right: 10px;
      border-radius: 10px;
      background: white; }

.work-nav {
  grid-column: 2/3;
  display: flex;
  margin-top: 20px; }
  @media (max-width: 768px) {
    .work-nav {
      order: 1;
      display: inline-flex;
      justify-content: space-between; } }
  .work-nav button {
    border: none;
    outline: none;
    display: inline-block;
    background: black;
    border-radius: 24px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .work-nav button svg {
      -webkit-transition: all 200ms cubic-bezier(0, 0, 0.21, 1.65);
      transition: all 200ms cubic-bezier(0, 0, 0.21, 1.65); }
    .work-nav button:nth-child(2) {
      margin-left: 20px; }
      .work-nav button:nth-child(2):hover svg {
        -webkit-transform: translateX(5px);
                transform: translateX(5px); }
      @media (max-width: 768px) {
        .work-nav button:nth-child(2):hover {
          -webkit-transform: translateX(0);
                  transform: translateX(0); } }
    .work-nav button:hover {
      background: #ffdb00; }
      .work-nav button:hover svg {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px); }
      .work-nav button:hover path {
        stroke: black; }
      @media (max-width: 768px) {
        .work-nav button:hover {
          background: black; }
          .work-nav button:hover svg {
            -webkit-transform: translateX(0);
                    transform: translateX(0); }
          .work-nav button:hover path {
            stroke: white; } }
    .work-nav button:active {
      background: #ffdb00; }
      .work-nav button:active svg {
        -webkit-transform: translateX(0);
                transform: translateX(0); }
      .work-nav button:active path {
        stroke: white; }

.work-counter {
  grid-column: 2/3;
  display: flex;
  height: 18px;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  line-height: 18px;
  overflow: hidden; }
  @media (max-width: 768px) {
    .work-counter {
      margin-top: 40px;
      justify-content: center; } }
  .work-counter .count {
    -webkit-transition: all 500ms cubic-bezier(0.31, 1.01, 0.55, 1.23);
    transition: all 500ms cubic-bezier(0.31, 1.01, 0.55, 1.23);
    -webkit-transform-origin: top left;
            transform-origin: top left; }

footer {
  height: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 40px; }

.cursor {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  -webkit-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
  z-index: 1;
  mix-blend-mode: exclusion;
  -webkit-mix-blend-mode: exclusion;
  -moz-mix-blend-mode: exclusion;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out; }
  @media (max-width: 768px) {
    .cursor {
      display: none; } }

