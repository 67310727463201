@import "./sass/query";
.App {
	display: flex;
}
h1 {
	font-size: 120px;
	letter-spacing: -10px;
	font-weight: 700;
	line-height: 100px;
	@include mobile {
		font-size: 48px;
		line-height: 50px;
		letter-spacing: -3px;
	}
}
h2 {
	font-size: 96px;
	letter-spacing: -5px;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 96px;
	@include mobile {
		font-size: 36px;
		line-height: 40px;
		letter-spacing: -3px;
	}
}
h3 {
	font-size: 30px;
	text-transform: uppercase;
	position: relative;
	z-index: 1;
	margin-top: 50px;
	line-height: 36px;
	&:after {
		content: "";
		width: calc(100% - 300px);
		display: block;
		height: 1px;
		background: #00e88d;
		position: absolute;
		bottom: 50%;
		right: 0;
		@include mobile {
			display: none;
		}
	}
}
h4 {
	font-weight: bold;
	position: relative;
	margin-bottom: 20px;
	&::after {
		content: "";
		display: block;
		height: 2px;
		width: 40px;
		position: absolute;
		bottom: -10px;
		left: 0;
		background: white;
	}
}
.button {
	margin-top: 40px;
	display: inline-block;
	padding: 20px 40px;
	color: white;
	text-decoration: none;
	font-weight: bold;
	text-transform: uppercase;
	background: black;
	border-radius: 50px;
	border: none;
	font-size: 16px;
	outline: none;
	&:hover {
		background: #ffdb00;
		color: black;
	}
}
.filter {
	display: none;
}
