@import "../../sass/query";

.header {
	width: 100%;
	min-height: 100vh;
	display: grid;
	grid-template-columns: 60% 1fr;
	align-items: center;
	.logo-mobile {
		display: none;
	}
	@include mobile {
		grid-template-columns: 100% 0;
		height: auto;
		padding: 50px 0;
		.sketch {
			display: none;
		}
		.logo-mobile {
			display: block;
		}
	}
	.name {
		font-size: 30px;
		padding-bottom: 20px;
		@include mobile {
			font-size: 16px;
			margin-top: 20px;
		}
	}
	.links {
		display: none;
		margin-top: 20px;
		a {
			color: white;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: 700;
			padding: 10px;
		}
		@include mobile {
			display: block;
		}
	}
}

// .left-eye {
// 	// transform: translateY(-15px);
// 	// transform: translateY(5px);
// 	// transform: translateX(35px);
// 	transform: translateX(-25px);
// }
// .right-eye {
// 	// transform: translateY(-15px);
// 	transform: translateY(0px);
// 	// transform: translateX(50px);
// 	transform: translateX(-25px);
// }
