$mobile-width: 768px;
$desktop-width: 1440px;

@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}